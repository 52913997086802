var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ProgressContext } from "@/context/ProgressContext";
import React, { useContext } from "react";
import { Button } from "./ui/Button";
import ProgressOption from "./ProgressOption";
var FormProgress = function () {
    return (React.createElement("div", { className: "flex gap-4 justify-center items-center" },
        React.createElement(ProgressOption, { page: 0, name: "Brand Details" }),
        React.createElement(ProgressOption, { page: 1, name: "Brand Relationship" }),
        React.createElement(ProgressOption, { page: 2, name: "Contact Details" }),
        React.createElement(ProgressOption, { page: 3, name: "Campaign Type" }),
        React.createElement(ProgressOption, { page: 4, name: "SMS Details" }),
        React.createElement(ProgressOption, { page: 5, name: "Campaign Details" })));
};
export function NextPageButton(_a) {
    var _b = _a.enabled, enabled = _b === void 0 ? false : _b, props = __rest(_a, ["enabled"]);
    var page = useContext(ProgressContext).page;
    return (React.createElement(Button, __assign({ disabled: !enabled || page === 6 }, props), "Next Page"));
}
export function PrevPageButton(_a) {
    var _b = _a.enabled, enabled = _b === void 0 ? true : _b, props = __rest(_a, ["enabled"]);
    var _c = useContext(ProgressContext), page = _c.page, setPage = _c.setPage;
    var handlePrevPage = function () {
        setPage(function (p) { return p - 1; });
    };
    return (React.createElement(Button, __assign({ variant: "outline", type: "button", onClick: handlePrevPage, disabled: !enabled || page === 0 }, props), "Previous Page"));
}
export function PageButtonGroup(_a) {
    var children = _a.children;
    return React.createElement("div", { className: "flex w-full mt-3 gap-4" }, children);
}
export default FormProgress;
