import React, { useContext } from "react";
import { ProgressContext } from "@/context/ProgressContext";
import { Check } from "lucide-react";
function ProgressOption(_a) {
    var page = _a.page, name = _a.name;
    var currentPage = useContext(ProgressContext).page;
    var isPageActive = page === currentPage;
    var isPageCompleted = page < currentPage;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col items-center gap-1" },
            React.createElement("div", { className: "".concat(isPageActive ? "border-4" : "border-2", " border-primary w-7 h-7 rounded-full ").concat(isPageCompleted ? "bg-primary" : "bg-white", " flex items-center justify-center") }, isPageCompleted && (React.createElement(Check, { size: 20, className: "mt-[2px] text-white" }))),
            React.createElement("p", { className: "text-xs" }, name)),
        React.createElement("div", { className: "border ".concat(isPageCompleted ? "border-primary" : "border-black/20", " w-16 -mt-5 -mx-6 last:hidden") })));
}
export default ProgressOption;
