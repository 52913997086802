var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { cn } from "@/lib/utils";
var Textarea = React.forwardRef(function (_a, ref) {
    var className = _a.className, label = _a.label, required = _a.required, props = __rest(_a, ["className", "label", "required"]);
    return (React.createElement("div", { className: "flex flex-col w-full" },
        React.createElement("label", { className: "text-sm capitalize" },
            label,
            " ",
            React.createElement("span", { className: "text-red-500" }, required ? "*" : "")),
        React.createElement("textarea", __assign({ className: cn("flex min-h-[80px] w-full rounded-md border ".concat(props.error
                ? "border-red-600"
                : "border-secondary/50", " bg-white px-3 py-2 text-sm placeholder:text-black/70 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 focus-visible:bg-black/10 focus-within:border-primary"), className), ref: ref }, props)),
        props.error || props.length ? (React.createElement("div", { className: "flex justify-between mt-1 ".concat(props.error ? "-mb-2" : "-mb-5") },
            React.createElement("p", { className: "text-xs text-red-600" }, props.error),
            props.length ? (React.createElement("p", { className: "text-xs" },
                props.length,
                " ",
                props.maxLength ? "/ ".concat(props.maxLength) : "",
                " ",
                "characters")) : null)) : null));
});
Textarea.displayName = "Textarea";
export { Textarea };
