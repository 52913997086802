import { z } from "zod";
export var BrandSMSSchema = z.object({
    sample1: z.string().min(2).max(1024),
    sample2: z.string().min(2).max(1024),
    helpKeywords: z.string().optional(),
    helpMessage: z.string().min(2),
    optOutKeywords: z.string().optional(),
    optOutMessage: z.string().min(2),
    optInMessage: z.string().optional(),
    optInKeywords: z.string().optional(),
});
