var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { legalForm, countries, verticalTypes, states } from "@/constants";
import { NextPageButton, PageButtonGroup } from "@/components/FormProgress";
import { Input, InputGroup } from "@/ui/input";
import { Controller, useForm } from "react-hook-form";
import PageTitle from "@/components/PageTitle";
import { BrandDetailsSchema } from "@/lib/resolvers";
import { zodResolver } from "@hookform/resolvers/zod";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, } from "@/ui/select";
import { BrandContext } from "@/context/BrandContext";
import { ProgressContext } from "@/context/ProgressContext";
export function BrandDetails() {
    var _a = useContext(BrandContext), brandDetails = _a.brandDetails, setBrandDetails = _a.setBrandDetails;
    var setPage = useContext(ProgressContext).setPage;
    var form = useForm({
        resolver: zodResolver(BrandDetailsSchema),
        defaultValues: brandDetails,
    });
    function handleSubmit(i) {
        setBrandDetails(i);
        setPage(function (p) { return p + 1; });
    }
    function handleTaxChange(e) {
        // Remove non-digit characters
        var value = e.target.value.replace(/[^0-9]/g, "");
        if (value.length > 2) {
            // Add hyphen after the second digit
            value = value.slice(0, 2) + "-" + value.slice(2);
        }
        form.setValue("taxNumber", value);
    }
    return (React.createElement("form", { onSubmit: form.handleSubmit(handleSubmit) },
        React.createElement(PageTitle, { title: "Brand Details" }),
        React.createElement(InputGroup, null,
            React.createElement(Input, __assign({ placeholder: "Legal Company Name", label: "Legal Company Name" }, form.register("legalCompanyName"), { required: true })),
            React.createElement(Input, __assign({ placeholder: "DBA or Brand Name, if different from legal name", label: "DBA or Brand Name, if different from legal name" }, form.register("dba"), { required: true }))),
        React.createElement(InputGroup, null,
            React.createElement(Controller, { control: form.control, name: "legalForm", render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(Select, { onValueChange: field.onChange },
                        React.createElement("div", { className: "flex flex-col w-full" },
                            React.createElement("label", { className: "text-sm capitalize" },
                                "What type of legal form is the organization?",
                                " ",
                                React.createElement("span", { className: "text-red-500" }, "*")),
                            React.createElement(SelectTrigger, null,
                                React.createElement(SelectValue, { placeholder: "Select Organization Type" }))),
                        React.createElement(SelectContent, null, legalForm.map(function (form) { return (React.createElement(SelectItem, { key: form, value: form }, form)); }))));
                } }),
            React.createElement(Controller, { control: form.control, name: "countryOfRegistration", render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(Select, { onValueChange: field.onChange },
                        React.createElement("div", { className: "flex flex-col w-full" },
                            React.createElement("label", { className: "text-sm capitalize" },
                                "Country of Registration?",
                                " ",
                                React.createElement("span", { className: "text-red-500" }, "*")),
                            React.createElement(SelectTrigger, null,
                                React.createElement(SelectValue, { placeholder: "Country of Registration" }))),
                        React.createElement(SelectContent, null, countries.map(function (c) { return (React.createElement(SelectItem, { key: c.code, value: c.code }, c.name)); }))));
                } })),
        React.createElement(InputGroup, null,
            React.createElement(Input, __assign({ placeholder: "12-3456789", label: "Tax Number / ID / EIN" }, form.register("taxNumber", {
                onChange: handleTaxChange,
            }), { required: true })),
            React.createElement(Controller, { control: form.control, name: "verticalType", render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(Select, { onValueChange: field.onChange },
                        React.createElement("div", { className: "flex flex-col w-full" },
                            React.createElement("label", { className: "text-sm capitalize" },
                                "Vertical Type",
                                " ",
                                React.createElement("span", { className: "text-red-500" }, "*")),
                            React.createElement(SelectTrigger, null,
                                React.createElement(SelectValue, { placeholder: "Select Vertical Type" }))),
                        React.createElement(SelectContent, null, verticalTypes.map(function (v) { return (React.createElement(SelectItem, { key: v, value: v }, v)); }))));
                } })),
        React.createElement(InputGroup, null,
            React.createElement(Input, __assign({ placeholder: "Address / Street", label: "Address / Street" }, form.register("street"), { required: true })),
            React.createElement(Input, __assign({ placeholder: "City", label: "City" }, form.register("city"), { required: true }))),
        React.createElement(InputGroup, null,
            React.createElement(Controller, { control: form.control, name: "state", render: function (_a) {
                    var field = _a.field;
                    return (React.createElement(Select, { onValueChange: field.onChange },
                        React.createElement("div", { className: "flex flex-col w-full" },
                            React.createElement("label", { className: "text-sm capitalize" },
                                "State",
                                React.createElement("span", { className: "text-red-500" }, "*")),
                            React.createElement(SelectTrigger, null,
                                React.createElement(SelectValue, { placeholder: "Select State" }))),
                        React.createElement(SelectContent, null, states.map(function (state) { return (React.createElement(SelectItem, { key: state, value: state }, state)); }))));
                } }),
            React.createElement(Input, __assign({ placeholder: "Postal / Zip Code", label: "Postal / Zip Code" }, form.register("zip"), { required: true }))),
        React.createElement(PageButtonGroup, null,
            React.createElement(NextPageButton, { type: "submit", enabled: form.formState.isValid }))));
}
