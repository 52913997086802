import React, { useContext } from "react";
import { Card, CardContent, CardHeader } from "@/ui/card";
import { BrandContext } from "@/context/BrandContext";
function BrandRelationshipCard(_a) {
    var relationship = _a.relationship;
    var _b = useContext(BrandContext), brandRelationship = _b.brandRelationship, setBrandRelationship = _b.setBrandRelationship;
    var isSelected = (brandRelationship === null || brandRelationship === void 0 ? void 0 : brandRelationship.name) === (relationship === null || relationship === void 0 ? void 0 : relationship.name);
    // set brand relationship
    function handleSetBrandRelationship() {
        setBrandRelationship(relationship);
    }
    return (React.createElement(Card, { className: "w-[33vw] min-w-80 cursor-pointer ".concat(isSelected ? "scale-105 bg-primary text-white" : ""), onClick: function () { return handleSetBrandRelationship(); } },
        React.createElement(CardHeader, { className: "bg-primary rounded-t-md text-white p-4" }, relationship.name),
        React.createElement(CardContent, { className: "p-4" }, relationship.description)));
}
export default BrandRelationshipCard;
