import React, { useContext } from "react";
import { relationships } from "@/constants";
import PageTitle from "../PageTitle";
import { NextPageButton, PageButtonGroup, PrevPageButton, } from "@/components/FormProgress";
import BrandRelationshipCard from "@/components/BrandRelationshipCard";
import { BrandContext } from "@/context/BrandContext";
import { ProgressContext } from "@/context/ProgressContext";
export function BrandRelationship() {
    var brandRelationship = useContext(BrandContext).brandRelationship;
    var setPage = useContext(ProgressContext).setPage;
    function handleNext() {
        if ((brandRelationship === null || brandRelationship === void 0 ? void 0 : brandRelationship.name) === "")
            return;
        setPage(function (p) { return p + 1; });
    }
    return (React.createElement("div", { className: "w-full" },
        React.createElement(PageTitle, { title: "Brand Relationship" }),
        React.createElement("div", { className: "flex gap-6 flex-wrap justify-center" }, relationships.map(function (r, i) { return (React.createElement(BrandRelationshipCard, { relationship: r, key: i })); })),
        React.createElement(PageButtonGroup, null,
            React.createElement(PrevPageButton, null),
            React.createElement(NextPageButton, { enabled: (brandRelationship === null || brandRelationship === void 0 ? void 0 : brandRelationship.name) !== "", onClick: function () { return handleNext(); } }))));
}
