var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import PageTitle from "@/components/PageTitle";
import { NextPageButton, PageButtonGroup, PrevPageButton, } from "@/components/FormProgress";
import { useForm } from "react-hook-form";
import { Input } from "@/ui/input";
import { Textarea } from "@/ui/textarea";
import { BrandContactSchema, } from "@/lib/resolvers/BrandContactResolver";
import { BrandContext } from "@/context/BrandContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProgressContext } from "@/context/ProgressContext";
export function BrandContact() {
    var setPage = useContext(ProgressContext).setPage;
    var _a = useContext(BrandContext), brandContact = _a.brandContact, setBrandContact = _a.setBrandContact;
    var form = useForm({
        defaultValues: brandContact,
        resolver: zodResolver(BrandContactSchema),
    });
    function handleSubmit(i) {
        setBrandContact(i);
        setPage(function (p) { return p + 1; });
    }
    var handleInputChange = function (e) {
        // Allows to delete space
        if (e.nativeEvent.inputType === "deleteContentBackward")
            return;
        // Remove non-digit characters
        var value = e.target.value.replace(/\D/g, "");
        // Add space after every 10 digits
        value = value.replace(/(\d{10})/g, "$1 ");
        // Set new value
        form.setValue("smsNumbers", value);
    };
    return (React.createElement("form", { onSubmit: form.handleSubmit(handleSubmit) },
        React.createElement(PageTitle, { title: "Brand Contact Information" }),
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement(Input, __assign({ placeholder: "Support Email Address", label: "Support Email Address", className: "" }, form.register("email"), { required: true })),
            React.createElement(Input, __assign({ placeholder: "Support Phone Number", label: "Support Phone Number" }, form.register("phone"), { required: true })),
            React.createElement(Textarea, __assign({ label: "Numbers needing SMS", placeholder: "Numbers needing SMS (separated with space)" }, form.register("smsNumbers", {
                onChange: handleInputChange,
            }), { required: true }))),
        React.createElement(PageButtonGroup, null,
            React.createElement(PrevPageButton, null),
            React.createElement(NextPageButton, { type: "submit", enabled: form.formState.isValid }))));
}
