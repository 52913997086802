import "./assets/css/index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrandProvider } from "./context/BrandContext";
import { ProgressProvider } from "./context/ProgressContext";
var root = ReactDOM.createRoot(document.getElementById("10dlc"));
root.render(React.createElement(ProgressProvider, null,
    React.createElement(BrandProvider, null,
        React.createElement(App, null))));
