var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState } from "react";
import { brandTypes } from "@/constants";
import { NextPageButton, PageButtonGroup, PrevPageButton, } from "@/components/FormProgress";
import { Checkbox } from "@/ui/checkbox";
import { ProgressContext } from "@/context/ProgressContext";
import { BrandContext } from "@/context/BrandContext";
export function BrandTypePage() {
    var setBrandType = useContext(BrandContext).setBrandType;
    var setPage = useContext(ProgressContext).setPage;
    var _a = useState(), selectedType = _a[0], setSelectedType = _a[1];
    var _b = useState([]), selectedSubTypes = _b[0], setSelectedSubTypes = _b[1];
    var handleMainCheckboxChange = function (type) {
        setSelectedType(type);
        // Clear selected subtypes when main type changes
        setSelectedSubTypes([]);
    };
    var handleSubCheckboxChange = function (subType) {
        setSelectedSubTypes(function (prev) {
            if (prev.includes(subType)) {
                // If the subtype is already selected, unselect it
                return prev.filter(function (type) { return type !== subType; });
            }
            else {
                // If the subtype is not selected, select it only if less than 5 are already selected
                return prev.length < 5 ? __spreadArray(__spreadArray([], prev, true), [subType], false) : prev;
            }
        });
    };
    var handleNextPage = function () {
        // Save selected types to context
        if (selectedType) {
            setBrandType({
                type: selectedType,
                subTypes: selectedSubTypes,
            });
            setPage(function (p) { return p + 1; });
        }
    };
    return (React.createElement("section", null,
        React.createElement("div", { className: "flex flex-col" }, brandTypes.map(function (type, i) {
            var _a;
            return (React.createElement("div", { key: type.name },
                React.createElement("div", { className: "flex items-center space-x-2" },
                    React.createElement(Checkbox, { id: type.name, checked: selectedType === type, onClick: function () { return handleMainCheckboxChange(type); } }),
                    React.createElement("label", { htmlFor: type.name, className: "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex justify-between w-full py-3 cursor-pointer" },
                        React.createElement("span", null,
                            React.createElement("b", null, type.name),
                            "- ",
                            type.description),
                        React.createElement("span", { className: "font-bold" },
                            "$",
                            type.price,
                            "/mo"))),
                selectedType === type &&
                    ((_a = type === null || type === void 0 ? void 0 : type.selectable) === null || _a === void 0 ? void 0 : _a.map(function (subType, i) { return (React.createElement("div", { className: "flex items-center space-x-2 ml-6", key: "".concat(subType, "-").concat(i) },
                        React.createElement(Checkbox, { key: subType, id: "s-".concat(subType), checked: selectedSubTypes.includes(subType), onClick: function () {
                                return handleSubCheckboxChange(subType);
                            } }),
                        React.createElement("label", { htmlFor: "s-".concat(subType), className: "text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-bold py-3 cursor-pointer" }, subType))); }))));
        })),
        React.createElement("p", { className: "mt-5 font-bold" }, "$30 Brand Registration Fee"),
        React.createElement(PageButtonGroup, null,
            React.createElement(PrevPageButton, null),
            React.createElement(NextPageButton, { enabled: selectedType !== null, onClick: handleNextPage }))));
}
