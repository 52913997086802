var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BrandSMSSchema, } from "@/lib/resolvers/BrandSMSResolver";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { NextPageButton, PageButtonGroup, PrevPageButton, } from "../FormProgress";
import PageTitle from "../PageTitle";
import { Textarea } from "../ui/textarea";
import { Input } from "../ui/input";
import { BrandContext } from "@/context/BrandContext";
import { ProgressContext } from "@/context/ProgressContext";
function BrandSMSFields() {
    var _a, _b, _c, _d, _e;
    var setPage = useContext(ProgressContext).setPage;
    var _f = useContext(BrandContext), smsDetails = _f.smsDetails, setSmsDetails = _f.setSmsDetails;
    var form = useForm({
        defaultValues: smsDetails,
        resolver: zodResolver(BrandSMSSchema),
    });
    function handleSubmit(i) {
        setSmsDetails(i);
        setPage(function (p) { return p + 1; });
    }
    return (React.createElement("form", { onSubmit: form.handleSubmit(handleSubmit) },
        React.createElement(PageTitle, { title: "SMS Details" }),
        React.createElement("div", { className: "flex flex-col gap-4 mb-10" },
            React.createElement(Textarea, __assign({ label: "Sample SMS Message 1", placeholder: "Sample SMS Message. Include as much details as possible to prevent any issues." }, form.register("sample1"), { maxLength: 1024, length: form.watch("sample1").length, error: (_a = form.formState.errors.sample1) === null || _a === void 0 ? void 0 : _a.message, required: true })),
            React.createElement(Textarea, __assign({ label: "Sample SMS Message 2", placeholder: "Sample SMS Message. Include as much details as possible to prevent any issues." }, form.register("sample2"), { maxLength: 1024, length: form.watch("sample2").length, error: (_b = form.formState.errors.sample2) === null || _b === void 0 ? void 0 : _b.message, required: true })),
            React.createElement(Input, __assign({ label: "Help Keywords", placeholder: "Type Help keywords here" }, form.register("helpKeywords"))),
            React.createElement(Textarea, __assign({ label: "Help Message", placeholder: "Type Help message here" }, form.register("helpMessage"), { error: (_c = form.formState.errors.helpMessage) === null || _c === void 0 ? void 0 : _c.message, length: form.watch("helpMessage").length, required: true })),
            React.createElement(Input, __assign({ label: "Opt-out Keywords", placeholder: "Type Opt-out keywords here" }, form.register("optOutKeywords"))),
            React.createElement(Textarea, __assign({ label: "Opt-out Message", placeholder: "Type Opt-out message here" }, form.register("optOutMessage"), { length: form.watch("optOutMessage").length, error: (_d = form.formState.errors.optOutMessage) === null || _d === void 0 ? void 0 : _d.message, required: true })),
            React.createElement(Input, __assign({ label: "Opt-In Keywords", placeholder: "Type Opt-In keywords here" }, form.register("optInKeywords"))),
            React.createElement(Textarea, __assign({ label: "Opt-in Message", placeholder: "Type Opt-in keywords here (optional)" }, form.register("optInMessage"), { error: (_e = form.formState.errors.optInMessage) === null || _e === void 0 ? void 0 : _e.message, length: form.watch("optInMessage").length }))),
        React.createElement(PageButtonGroup, null,
            React.createElement(PrevPageButton, null),
            React.createElement(NextPageButton, { type: "submit", enabled: form.formState.isValid }))));
}
export default BrandSMSFields;
