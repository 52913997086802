import React, { useContext } from "react";
import { BrandDetails, BrandRelationship, BrandContact, BrandTypePage, BrandCampaignDetails, } from "@/components/pages";
import FormProgress from "./components/FormProgress";
import { ProgressContext } from "./context/ProgressContext";
import BrandSMSFields from "./components/pages/BrandSMSFields";
import Completed from "./components/pages/Completed";
export default function App() {
    var page = useContext(ProgressContext).page;
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "bg-secondary w-full h-[70px] flex items-center justify-center" },
            React.createElement("h1", { className: "text-xl lg:text-3xl font-semibold text-white" }, "SMS Campaign Registration (10DLC)")),
        React.createElement("div", { className: "flex flex-col w-[90%] h-auto rounded-lg p-10  m-auto pt-4" },
            React.createElement(FormProgress, null),
            page === 0 && React.createElement(BrandDetails, null),
            page === 1 && React.createElement(BrandRelationship, null),
            page === 2 && React.createElement(BrandContact, null),
            page === 3 && React.createElement(BrandTypePage, null),
            page === 4 && React.createElement(BrandSMSFields, null),
            page === 5 && React.createElement(BrandCampaignDetails, null),
            page === 6 && React.createElement(Completed, null))));
}
// TODO social media / website url required
// TODO 3 sample messages
// TODO help/stop inputs
