import React, { createContext, useState } from "react";
var defaultBrandDetails = {
    legalCompanyName: "",
    dba: "",
    legalForm: "",
    countryOfRegistration: "",
    taxNumber: "",
    verticalType: "",
    street: "",
    city: "",
    state: "",
    zip: "",
};
var defaultBrandSmsDetails = {
    sample1: "",
    sample2: "",
    helpKeywords: "",
    helpMessage: "",
    optOutKeywords: "",
    optOutMessage: "",
    optInKeywords: "",
    optInMessage: "",
};
var defaultBrandRelationship = {
    name: "",
    description: "",
};
var defaultBrandContact = {
    email: "",
    phone: "",
    smsNumbers: "",
};
var defaultCampaignAttributes = {
    name: "",
    description: "",
};
var defaultBrandType = {
    type: {
        name: "",
        description: "",
        price: 0,
    },
    subTypes: [],
};
export var BrandContext = createContext({
    brandDetails: defaultBrandDetails,
    setBrandDetails: function () { },
    brandRelationship: defaultBrandRelationship,
    setBrandRelationship: function () { },
    brandContact: defaultBrandContact,
    setBrandContact: function () { },
    brandType: defaultBrandType,
    setBrandType: function () { },
    brandAttributes: [defaultCampaignAttributes],
    setBrandAttributes: function () { },
    smsDetails: defaultBrandSmsDetails,
    setSmsDetails: function () { },
});
export var BrandProvider = function (_a) {
    var children = _a.children;
    var _b = useState(defaultBrandType), brandType = _b[0], setBrandType = _b[1];
    var _c = useState(defaultBrandDetails), brandDetails = _c[0], setBrandDetails = _c[1];
    var _d = useState(defaultBrandRelationship), brandRelationship = _d[0], setBrandRelationship = _d[1];
    var _e = useState([]), brandAttributes = _e[0], setBrandAttributes = _e[1];
    var _f = useState(defaultBrandSmsDetails), smsDetails = _f[0], setSmsDetails = _f[1];
    var _g = useState(defaultBrandContact), brandContact = _g[0], setBrandContact = _g[1];
    return (React.createElement(BrandContext.Provider, { value: {
            brandDetails: brandDetails,
            setBrandDetails: setBrandDetails,
            brandRelationship: brandRelationship,
            setBrandRelationship: setBrandRelationship,
            brandContact: brandContact,
            setBrandContact: setBrandContact,
            brandType: brandType,
            setBrandType: setBrandType,
            brandAttributes: brandAttributes,
            setBrandAttributes: setBrandAttributes,
            smsDetails: smsDetails,
            setSmsDetails: setSmsDetails,
        } }, children));
};
