var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext } from "react";
import { attributes, requiredAttributes } from "@/constants";
import { PageButtonGroup, PrevPageButton, } from "@/components/FormProgress";
import { Checkbox } from "@/ui/checkbox";
import { ProgressContext } from "@/context/ProgressContext";
import { BrandContext } from "@/context/BrandContext";
import PageTitle from "../PageTitle";
import { Input, InputGroup } from "../ui/input";
import { Controller, useForm } from "react-hook-form";
import { CampaignDetailsSchema, } from "@/lib/resolvers/CampaignDetailsResolver";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/Button";
import axios from "axios";
export function BrandCampaignDetails() {
    var _a;
    var setPage = useContext(ProgressContext).setPage;
    var _b = useContext(BrandContext), brandAttributes = _b.brandAttributes, brandContact = _b.brandContact, brandDetails = _b.brandDetails, brandType = _b.brandType, brandRelationship = _b.brandRelationship, setBrandAttributes = _b.setBrandAttributes, smsDetails = _b.smsDetails;
    var form = useForm({
        // defaultValues: brandType,
        resolver: zodResolver(CampaignDetailsSchema),
    });
    function handleSelectedAttributes(attribute) {
        setBrandAttributes(function (prev) {
            if (prev.includes(attribute)) {
                return prev.filter(function (a) { return a !== attribute; });
            }
            else {
                return __spreadArray(__spreadArray([], prev, true), [attribute], false);
            }
        });
    }
    // console.log(form.formState.errors);
    function handleSubmit(i) {
        return __awaiter(this, void 0, void 0, function () {
            var attributes, campaignTypes, address, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Check agreement checked
                        if (!i.agreement) {
                            alert("Please agree to the terms");
                            return [2 /*return*/];
                        }
                        attributes = brandAttributes.map(function (a) { return a.name; }).join(", ");
                        campaignTypes = {
                            name: brandType.type.name,
                            subTypes: brandType.subTypes.map(function (s) { return s; }).join(", "),
                        };
                        address = "".concat(brandDetails.street, ", ").concat(brandDetails.city, ", ").concat(brandDetails.state, " ").concat(brandDetails.zip, ", ").concat(brandDetails.countryOfRegistration);
                        return [4 /*yield*/, axios.post(process.env.REACT_APP_SUBMIT_URL, __assign({ address: address, brandDetails: brandDetails, contact: brandContact, campaignTypes: campaignTypes, brandRelationship: brandRelationship, sms: smsDetails, attributes: attributes }, i))];
                    case 1:
                        data = (_a.sent()).data;
                        if (data === null || data === void 0 ? void 0 : data.success) {
                            setPage(function (p) { return p + 1; });
                        }
                        else {
                            alert("Error submitting form. Please try again.");
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement("form", { onSubmit: form.handleSubmit(handleSubmit) },
        React.createElement(PageTitle, { title: "Campaign Details" }),
        React.createElement(Textarea, __assign({ label: "Campaign Description (40 characters minimum)", placeholder: "Campaign Description" }, form.register("description"), { length: (_a = form.watch("description")) === null || _a === void 0 ? void 0 : _a.length, required: true })),
        React.createElement(InputGroup, null,
            React.createElement(Input, __assign({ label: "Brand Website URL", placeholder: "www.example.com" }, form.register("website"), { required: true })),
            React.createElement(Input, __assign({ label: "Brand Privacy Policy URL", placeholder: "www.example.com/privacy" }, form.register("privacyUrl"), { required: true }))),
        React.createElement("h2", { className: "font-semibold text-xl my-2" }, "Campaign & Content Attributes"),
        attributes.map(function (attribute) { return (React.createElement("div", { className: "flex items-center space-x-2", key: attribute.name },
            React.createElement(Checkbox, { key: attribute.name, id: attribute.name, onClick: function () { return handleSelectedAttributes(attribute); } }),
            React.createElement("label", { htmlFor: attribute.name, className: "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-full py-3 cursor-pointer" },
                React.createElement("b", null, attribute.name),
                " ",
                attribute.description && " - ".concat(attribute.description)))); }),
        React.createElement("h2", { className: "font-semibold text-xl my-2" }, "Required Attributes"),
        requiredAttributes.map(function (attribute) { return (React.createElement("div", { className: "flex items-center space-x-2", key: attribute },
            React.createElement(Checkbox, { key: attribute, id: "r-".concat(attribute), checked: true }),
            React.createElement("label", { htmlFor: "r-".concat(attribute), className: "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-full py-3 cursor-pointer" },
                React.createElement("b", null, attribute)))); }),
        React.createElement("p", { className: "my-4 text-lg" },
            React.createElement("b", null, "Approval process could take up to 30 Days")),
        React.createElement(Input, __assign({ label: "Your Name", placeholder: "Your Name" }, form.register("name"), { required: true })),
        React.createElement(Controller, { control: form.control, name: "agreement", render: function (_a) {
                var field = _a.field;
                return (React.createElement("div", { className: "flex items-center space-x-2" },
                    React.createElement(Checkbox, { id: "agreement", onCheckedChange: field.onChange }),
                    React.createElement("label", { htmlFor: "agreement", className: "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-full py-3 cursor-pointer mt-2" },
                        React.createElement("b", null, "Agreement *"),
                        " - by submitting this form for 10DLC/SMS Campaign registration, you agree to comply with TCPA laws, CTIA Best Practices, and T-Mobile Messaging Guidelines")));
            } }),
        React.createElement(PageButtonGroup, null,
            React.createElement(PrevPageButton, null),
            React.createElement(Button, { type: "submit", disabled: !form.formState.isValid }, "Submit"))));
}
