var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { cn } from "@/lib/util";
import { cva } from "class-variance-authority";
// Icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
var buttonVariants = cva("w-full active:scale-95 inline-flex items-center justify-center rounded-md text-md font-medium transition-colors disabled:opacity-50 disabled:pointer-events-none my-2 shadow-md shadow-black/20", {
    variants: {
        variant: {
            default: "bg-button text-white hover:bg-buttonHover",
            destructive: "bg-error text-white hover:bg-red-600",
            outline: "bg-transparent text-primary hover:bg-buttonHover hover:text-white border-2 border-button hover:text-white hover:border-buttonHover",
            warning: "bg-warning text-white hover:bg-warning",
            ghost: "bg-transparent text-black hover:bg-secondary/5 data-[state=open]:bg-transparent shadow-none",
            cancel: "bg-transparent text-button border border-button hover:bg-buttonHover hover:text-white ",
            icon: "bg-transparent text-secondary border w-[35px] h-[35px] rounded-full hover:bg-secondary hover:text-white hover:border-secondary",
        },
        size: {
            default: "p-2",
            xs: "h-6 px-2",
            sm: "h-9 px-2",
            md: "p-3 ",
            lg: "p-4",
            xl: "p-5 text-xl font-semibold",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
});
var Button = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, variant = _a.variant, isLoading = _a.isLoading, size = _a.size, type = _a.type, isDisabled = _a.isDisabled, props = __rest(_a, ["className", "children", "variant", "isLoading", "size", "type", "isDisabled"]);
    return (React.createElement("button", __assign({ className: cn(buttonVariants({ variant: variant, size: size }), className), ref: ref, disabled: isLoading || isDisabled, type: type || "button" }, props), isLoading ? (React.createElement(React.Fragment, null,
        React.createElement(AiOutlineLoading3Quarters, { className: "mr-2 h-4 w-4 animate-spin" }),
        "Loading...")) : (children)));
});
Button.displayName = "Button";
export { Button, buttonVariants };
