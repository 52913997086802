import React, { createContext, useState } from "react";
export var ProgressContext = createContext({
    page: 0,
    setPage: function () { },
});
export var ProgressProvider = function (_a) {
    var children = _a.children;
    var _b = useState(0), page = _b[0], setPage = _b[1];
    return (React.createElement(ProgressContext.Provider, { value: { page: page, setPage: setPage } }, children));
};
