import { z } from "zod";
export var BrandDetailsSchema = z.object({
    legalCompanyName: z.string().min(2),
    dba: z.string().min(2),
    legalForm: z.string(),
    countryOfRegistration: z.string(),
    taxNumber: z.string().min(9),
    verticalType: z.string(),
    street: z.string().min(2),
    city: z.string().min(2),
    state: z.string().min(2),
    zip: z.string().min(5),
});
